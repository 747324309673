const oldFile = {
  indexes: [
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'estimatedDispatchTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'estimatedPickupTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'confirmedAt',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'acceptedAt',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'locations.`0`.estimatedArrivalTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'driverId',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'confirmedAt',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'scheduconstimestamp',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'scheduconstimestamp',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'confirmedAt',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'createdAt',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'queries',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'uid',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'timestamp',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'isScheduled',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'isScheduled',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'isScheduled',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'scheduconstimestamp',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'scheduconstimestamp',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'isScheduled',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'type',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'source',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'state',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'dropOffTimestampMs',
          order: 'ASCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'customerId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'createdAt',
          order: 'DESCENDING',
        },
      ],
    },
    {
      collectionGroup: 'trips',
      queryScope: 'COLLECTION',
      fields: [
        {
          fieldPath: 'cashedOutAt',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'driverId',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'status',
          order: 'ASCENDING',
        },
        {
          fieldPath: 'driverEarningsD',
          order: 'ASCENDING',
        },
      ],
    },
  ],
  fieldOverrides: [],
};

export default oldFile;
