import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import combinations from '../../../../functions/combinations';
import {simulateDownloadClick} from '../../../../functions/simulateDownloadClick';
import Field from './Field';
import oldFile from './oldFile';

export default function computeFirestoreIndexes() {
  console.clear();

  // get field combinations

  const alwaysOptionalFields = [Field.source, Field.driverId, Field.state, Field.vendorId, Field.type];

  const fieldsArrays: Field[][] = [
    [Field.status, Field.firstScheduledTimestampMs],
    ...combinations(alwaysOptionalFields, 1).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 2).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 3).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 4).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 5).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      ...fields,
    ]),

    [Field.status, Field.firstScheduledTimestampMsDescending],
    ...combinations(alwaysOptionalFields, 1).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 2).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 3).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 4).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 5).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      ...fields,
    ]),

    [Field.status, Field.createdAt],
    ...combinations(alwaysOptionalFields, 1).map((fields: Field[]) => [Field.status, Field.createdAt, ...fields]),
    ...combinations(alwaysOptionalFields, 2).map((fields: Field[]) => [Field.status, Field.createdAt, ...fields]),
    ...combinations(alwaysOptionalFields, 3).map((fields: Field[]) => [Field.status, Field.createdAt, ...fields]),
    ...combinations(alwaysOptionalFields, 4).map((fields: Field[]) => [Field.status, Field.createdAt, ...fields]),
    ...combinations(alwaysOptionalFields, 5).map((fields: Field[]) => [Field.status, Field.createdAt, ...fields]),

    [Field.status, Field.firstScheduledTimestampMs, Field.createdAt],
    ...combinations(alwaysOptionalFields, 1).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 2).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 3).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 4).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 5).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMs,
      Field.createdAt,
      ...fields,
    ]),

    [Field.status, Field.firstScheduledTimestampMsDescending, Field.createdAt],
    ...combinations(alwaysOptionalFields, 1).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 2).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 3).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 4).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      Field.createdAt,
      ...fields,
    ]),
    ...combinations(alwaysOptionalFields, 5).map((fields: Field[]) => [
      Field.status,
      Field.firstScheduledTimestampMsDescending,
      Field.createdAt,
      ...fields,
    ]),
  ];
  console.log(
    'fieldsArrays',
    fieldsArrays.map(fields => fields.map(field => field.path)),
  );

  // const fieldsArrays = [
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 1),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 2),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 3),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 4),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 5),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 6),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMs], 7),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 1),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 2),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 3),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 4),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 5),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 6),
  //     ...combinations([...alwaysOptionalFields, Field.firstScheduledTimestampMsDescending], 7),
  // ].map(
  //     fields => [Field.status, ...fields]
  // )

  /*
    array = [1, 2, 3, 4, 5, 6, 7]
count = 5
all = combinations(array, count)
console.log(all)

function factorial(number) {
  let result = 1
  for (i = 1; i <= number; i++) {
    result = result * i
  }
  return result
}

function numberOfCombinations(n, r) {
  return factorial(n) / (factorial(r) * factorial(n - r))
}

number = numberOfCombinations(array.length, count)
didPass = all.length === number
console.log('didPass', didPass, number)

    */

  // const alwaysRequiredFields = [Field.status]
  // const alwaysOptionalFields = [Field.source, Field.driverId, Field.state, Field.vendorId, Field.type]
  // const fieldsArrays = [
  //     ...combinations([...alwaysRequiredFields, Field.firstScheduledTimestampMs], [Field.createdAt, ...alwaysOptionalFields]),
  //     ...combinations([...alwaysRequiredFields, Field.firstScheduledTimestampMsDescending], [Field.createdAt, ...alwaysOptionalFields]),
  //     ...combinations([...alwaysRequiredFields, Field.createdAt], [Field.firstScheduledTimestampMs, ...alwaysOptionalFields]),
  //     ...combinations([...alwaysRequiredFields, Field.createdAt], [Field.firstScheduledTimestampMsDescending, ...alwaysOptionalFields]),
  // ]

  // order the field arrays

  const orderedFields = fieldsArrays.map(orderFields);
  console.log(
    'orderedFields',
    orderedFields.map(fields => fields.map(field => field.path)),
  );

  // convert to index objects

  const indexObjects = orderedFields.map(indexObject);
  console.log('indexObjects', indexObjects);

  // add old index objects

  const allIndexObjects = [...indexObjects, ...oldFile.indexes];
  console.log('allIndexObjects', allIndexObjects);

  // get unique index objects

  const uniqueIndexObjects = uniqBy(allIndexObjects, object => JSON.stringify(object));
  console.log('uniqueIndexObjects', uniqueIndexObjects);

  // download file

  const file = {indexes: uniqueIndexObjects, fieldOverrides: []};
  const json = JSON.stringify(file, null, '   ');
  simulateDownloadClick(`data:text/json;charset=utf-8,${encodeURIComponent(json)}`, 'firestore.indexes.json');
}

function orderFields(fields: Field[]): Field[] {
  // sort alphabetically

  let orderedFields = sortBy(fields, 'path');

  // if "firstScheduledTimestampMs", move to the end

  const indexOfFirstScheduledTimestampMs = orderedFields.findIndex(
    field => field.path === Field.firstScheduledTimestampMs.path,
  );
  if (indexOfFirstScheduledTimestampMs > -1)
    orderedFields.push(...orderedFields.splice(indexOfFirstScheduledTimestampMs, 1));

  // // if "createdAt", move to the end

  const indexOfCreatedAt = orderedFields.findIndex(field => field === Field.createdAt);
  if (indexOfCreatedAt > -1) orderedFields.push(...orderedFields.splice(indexOfCreatedAt, 1));

  return orderedFields;
}

function indexObject(fields: Field[]) {
  return {
    collectionGroup: 'trips',
    queryScope: 'COLLECTION',
    fields: fields.map(field => ({
      fieldPath: field.path,
      order: field.direction,
    })),
  };
}
