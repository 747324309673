export default function combinations(array, length) {
  if (length === 1) {
    return array.map(item => [item]);
  } else {
    const results = array.flatMap((item, i) => {
      const remainingArray = array.slice(i + 1, array.length);
      const remainingLength = length - 1;
      const remainingCombinations = combinations(remainingArray, remainingLength);
      if (Array.isArray(remainingCombinations[0])) {
        return remainingCombinations.map(rem => [item, ...rem]);
      } else {
        return remainingCombinations.map(rem => [item, rem]);
      }
    });
    return results;
  }
}
