import React from 'react';

export default function Scripts() {
  return (
    <div>
      <h2>Scripts</h2>
      <ol>
        <li>
          <p>Get approved drivers, export to CSV with name and email on record.</p>
          <pre className="code">
            firebase.database().ref('drivers').once('value').then(snapshot ={'>'} {'{'}
            var string = '' snapshot.forEach(snapshot ={'>'} {'{'}
            var driver = snapshot.val() if (driver.status !== 'approved') return if (driver.isTestingAccount) return
            string = string + driver.firstName + ',' + driver.lastName + ',' + driver.email + '\n'
            {'}'}) console.log(string){'}'})
          </pre>
        </li>
        <li>
          <p>Get all devices for a UID</p>
          <pre className="code">
            firebase.database().ref('devices').orderByChild('uid').equalTo('kjNGmoCoo9c1l3JfYtc2xCGXSzh2').once('value').then(snapshot
            ={'>'} {'{'}
            console.log(snapshot.val()) console.log(Object.values(snapshot.val()).map(device ={'>'}{' '}
            device.fcmRegistrationToken).join('\n')){'}'})
          </pre>
        </li>
        <li>
          <p>Send invite to driver</p>
          <pre className="code">
            firebase.database().ref('/drivers-available/jQNTXXYV8aSiwJZVKKivsBMYJ9n1/invites/1234567890').update( {'{'}{' '}
            "acceptedInvites": {'{'} "jQNTXXYV8aSiwJZVKKivsBMYJ9n1": {'{'} "firstName": "Tim", "lastName": "Testing",
            "phone": "+17047062272"
            {'}'}
            {'}'}, "amountCharged": 0, "completedAt": 1604355952468, "confirmedAt": 1604355939294, "couponCode":
            "free123", "createdAt": (new Date().valueOf()), "customer": {'{'} "cellphone": "+17047062272", "firstName":
            "Test", "lastName": "Account"{'}'}, "customerId": "WFmY9surR7PlWsx5sAc5SJylgR73", "deviceType": "IOS",
            "directions": "jTI63nIFVHCyfTtcRL0u", "estimate": 0, "firstColor": "#00F11D", "fromAddress": "7119 Thornrose
            Dr, Charlotte, NC 28210, USA", "fromCoordinate": [ 35.13462813252466, -80.87164710233317 ], "isCashedOut":
            false, "isWomenOnly": false, "payout": 0, "secondColor": "#000000", "source": "IOS", "source_createdAt":
            "IOS_1604355935407", "startedAt": 1604355950688, "status": "COMPLETED", "tip": 0, "tipWasCaptured": true,
            "toAddress": "7115 South Blvd, Charlotte, NC 28273, USA", "toCoordinate": [ 35.13681569999999, -80.8753256
            ], "toPlaceId": "ChIJaRWBnZ-eVogRlCj55vwAKrE", "toPlaceName": "QuikTrip", "type": "scoopM"{'}'})
          </pre>
          <p>Mark accepted</p>
          <pre className="code">
            firebase.database().ref('/drivers-available/jQNTXXYV8aSiwJZVKKivsBMYJ9n1/invites/1234567890').update( {'{'}{' '}
            "acceptedInvites": {'{'} "jQNTXXYV8aSiwJZVKKivsBMYJ9n1": {'{'} "firstName": "Tim", "lastName": "Testing",
            "phone": "+17047062272"
            {'}'}
            {'}'}, "amountCharged": 0, "completedAt": 1604355952468, "confirmedAt": 1604355939294, "couponCode":
            "free123", "createdAt": 1604357783430, "customer": {'{'} "cellphone": "+17047062272", "firstName": "Test",
            "lastName": "Account"{'}'}, "customerId": "WFmY9surR7PlWsx5sAc5SJylgR73", "deviceType": "IOS", "directions":
            "jTI63nIFVHCyfTtcRL0u", "estimate": 0, "firstColor": "#00F11D", "fromAddress": "7119 Thornrose Dr,
            Charlotte, NC 28210, USA", "fromCoordinate": [ 35.13462813252466, -80.87164710233317 ], "isCashedOut":
            false, "isWomenOnly": false, "payout": 0, "secondColor": "#000000", "source": "IOS", "source_createdAt":
            "IOS_1604355935407", "startedAt": 1604355950688, "status": "COMPLETED", "tip": 0, "tipWasCaptured": true,
            "toAddress": "7115 South Blvd, Charlotte, NC 28273, USA", "toCoordinate": [ 35.13681569999999, -80.8753256
            ], "toPlaceId": "ChIJaRWBnZ-eVogRlCj55vwAKrE", "toPlaceName": "QuikTrip", "type": "scoopM"{'}'})
          </pre>
        </li>
        <li>
          <p>Clone trips table to Firestore</p>
          <pre className="code">
            firebase.database().ref('/trips').once('value').then(snapshot ={'>'}
            {'{'}
            const tripCustomers = snapshot.val() //const testBatch = {'{'}
            {'}'}
            const batch = firebase.firestore().batch() Object.entries(tripCustomers).forEach(([customerId, trips]) =
            {'>'} {'{'}
            Object.entries(trips).forEach(([tripId, trip]) ={'>'} {'{'}
            //testBatch[tripId] = trip if (!trip || !tripId) {'{'}
            console.log(tripId, trip) console.log(firebase.firestore().collection('trips').doc(tripId))
            {'}'}
            // batch.update(firebase.firestore().collection('trips').doc(tripId), trip)
            {'}'}){'}'}) //console.log(testBatch["-MJr5nMiS06gNPb6--hH"]) // batch.commit().then(() ={'>'} {'{'}
            // console.log('DONE!') // {'}'}, error ={'>'} {'{'}
            // console.error(error) // {'}'}){'}'})
          </pre>
        </li>
        <li>
          <p>Get trip by OLO delivery ID</p>
          <pre className="code">
            firebase.database().ref('trips-all').orderByChild('oloDeliveryId').equalTo('b7fd5c28-e442-4ce6-8735-fe6decb37886').once('value').then(snapshot
            ={'>'} console.log(snapshot.val()))
          </pre>
        </li>
        <li>
          <p>Pass a trip through to completion</p>
          <pre className="code">
            let db = firebase.database() function wait() {'{'}
            return new Promise((resolve, reject) ={'>'} {'{'}
            setTimeout(resolve, 8000){'}'}){'}'}
            function updateTrip(customerId, tripId) {'{'}
            return db.ref(`/trips/${'{'}customerId{'}'}/${'{'}tripId{'}'}
            `).update({'{'}
            status: 'ACCEPTED', confirmedAt: (new Date()).valueOf(), driverId: 'LzAytHdc0RUoEsPFnpsLzTUnhCt2', driver:{' '}
            {'{'}
            firstName: 'Wave', lastName: 'Official Car', phone: '+18339283263'
            {'}'}
            {'}'}).then(wait).then(() ={'>'}
            db.ref(`/trips/${'{'}customerId{'}'}/${'{'}tripId{'}'}`).update(
            {'{'}
            status: 'STARTED', startedAt: (new Date()).valueOf(),{'}'}
            ).then(wait).then(() ={'>'}
            db.ref(`/trips/${'{'}customerId{'}'}/${'{'}tripId{'}'}`).update(
            {'{'}
            status: 'COMPLETED', completedAt: (new Date()).valueOf(),{'}'}) ) ){'}'}
            updateTrip(customerId, tripId)
          </pre>
        </li>

        <li>
          <p>Set up `isScheduled` for trips table</p>
          <pre className="code">
            Trip.setUpIsScheduledFor = async (tripEntries = null, index = 0) ={'>'} {'{'}
            console.log(index) const trips = tripEntries || Object.entries((await
            admin.database().ref('/trips-all').once('value')).val()) const [tripId, trip] = trips[index] const
            isScheduled = Trip.prototype.isScheduledWithDefault.apply(trips[index][1]) console.log('Setting',
            trip.customerId, tripId, isScheduled) await Trip.setIsScheduled(tripId, trip.customerId, isScheduled) await
            wait(250) return Trip.setUpIsScheduledFor(trips, index + 1){'}'}
          </pre>
        </li>

        <li>
          <p>Remove dud trips</p>
          <pre className="code">
            // clear() console.log('============================== NEW ==============================') function wait(){' '}
            {'{'}
            return new Promise(resolve ={'>'} {'{'}
            setTimeout(() ={'>'} {'{'}
            resolve(){'}'}, 100){'}'}){'}'}
            firebase.firestore().collection('/trips').get().then(snapshot ={'>'} {'{'}
            const toFix = snapshot.docs.map(doc ={'>'} ([doc.id, doc.data()])).filter(([id, trip]) ={'>'}
            Object.keys(trip).length {'<'} 4 ) fix(toFix){'}'}) //
            firebase.database().ref('/trips-all').once('value').then(snapshot ={'>'} {'{'}
            // let tripEntries = [] // const toFix = Object.entries(snapshot.val()).filter(([id, trip]) ={'>'}
            // Object.keys(trip).length {'<'} 4 // ) // fix(toFix) //{'}'}) //
            firebase.database().ref('/trips').once('value').then(snapshot ={'>'} {'{'}
            // let tripEntries = [] // Object.entries(snapshot.val()).forEach(([customerId, tripsCustomer]) ={'>'} {'{'}
            // tripEntries.push(...Object.entries(tripsCustomer).map(([tripId, trip]) ={'>'}
            // ([tripId, Object.assign(trip, {'{'}
            {'}'}customerId{'}'})]) // )) // {'}'}) // const docsToFix = tripEntries.filter(([id, trip]) ={'>'}
            // Object.keys(trip).length {'<'} 4 // ) // fix(docsToFix) //{'}'}) function fix(docs, i = 0) {'{'}
            if (i === docs.length) return console.log('Done!') console.log('Deleting', i, docs[i][0], `/trips/${'{'}
            docs[i][0]{'}'}
            `, docs[i][1]) // Promise.resolve('TEST') return
            firebase.firestore().collection('trips').doc(docs[i][0]).delete() .then(wait) .then(() ={'>'} fix(docs, i +
            1)){'}'}
          </pre>
        </li>

        <li>
          <p>Set up `isScheduled` on trips table</p>
          <pre className="code">
            function wait() {'{'}
            return new Promise((resolve, reject) ={'>'} {'{'}
            setTimeout(() ={'>'} {'{'}
            resolve(){'}'}, 100){'}'}){'}'}
            async function setUpIsScheduledFor(tripEntries = null, index = 0) {'{'}
            if (tripEntries && (index === tripEntries.length)) return console.log('Done') console.log(index) const trips
            = tripEntries || Object.entries((await firebase.database().ref('/trips-all').once('value')).val()) if
            (!tripEntries) console.log(trips.length + ' trips') const [tripId, trip] = trips[index] const {'{'}{' '}
            customerId{'}'} = trip if (!tripId || !customerId) {'{'}
            console.log('Not enough info', customerId, tripId) return setUpIsScheduledFor(trips, index + 1){'}'}
            let isScheduled = false if (trip.scheduleTimestamp) isScheduled = true else if (trip.dropOffTimestampMs)
            isScheduled = true else if (trip.locations && trip.locations[0] && trip.locations[0].scheduledTimestampMs)
            isScheduled = true else if (trip.locations && trip.locations[1] && trip.locations[1].scheduledTimestampMs)
            isScheduled = true console.log(trip.customerId, tripId, isScheduled) // await
            firebase.database().ref(`/trips/${'{'}trip.customerId{'}'}/${'{'}
            tripId{'}'}/isScheduled`).set(isScheduled) await wait(250) return setUpIsScheduledFor(trips, index + 1)
            {'}'}
            setUpIsScheduledFor()
          </pre>
        </li>

        <li>
          <p>Clean drivers table</p>
          <pre className="code">
            clear() firebase.database().ref('drivers').once('value').then(snapshot ={'>'} {'{'}
            const driverEntries = Object.entries(snapshot.val()) console.log(driverEntries) const badDriverEntries =
            driverEntries.filter(([driverId, driver]) ={'>'} {'{'}
            const invalidId = driverId[0] === '-' const invalidIdLength = driverId.length !== 28 const falsyDriver =
            !driver const invalidDriver = !driver.firstName || !driver.lastName return invalidId || invalidIdLength ||
            falsyDriver{'}'}) console.log(badDriverEntries) const update = Object.assign({'{'}
            {'}'}, ...badDriverEntries.map(([driverId]) ={'>'} ({'{'} [driverId] : null{'}'}))) console.log(update) //
            return firebase.database().ref('drivers').update(update).then(() ={'>'} {'{'}
            // console.log('Done') // {'}'}){'}'}).catch(error ={'>'} {'{'}
            console.error('error', error){'}'})
          </pre>
        </li>

        <li>
          <p>Clean trips -- MAKE SURE TO NOT DELETE IF WE CAN RATHER FIX</p>
          <pre className="code">
            clear() firebase.database().ref('trips').once('value').then(snapshot ={'>'} {'{'}
            const tripCustomerEntries = Object.entries(snapshot.val()) console.log('tripCustomerEntries',
            tripCustomerEntries) const tripEntries = [] tripCustomerEntries.forEach(([customerId, tripCustomer]) ={
              '>'
            }{' '}
            {'{'}
            tripEntries.push(...Object.entries(tripCustomer).map(([tripId, trip]) ={'>'} [tripId, Object.assign(trip,{' '}
            {'{'}__customerId: customerId{'}'})])){'}'}) console.log('tripEntries', tripEntries) const badTripEntries =
            tripEntries.filter(([tripId, trip]) ={'>'} {'{'}
            // return tripId[0] !== '-' // return tripId.length !== 20 // return !trip // return !trip.customerId //
            return !trip.customer return Boolean(trip.current){'}'}) console.log('badTripEntries', badTripEntries) //
            console.log(badTripEntries.map(([tripId, trip]) ={'>'} trip.current && trip.current.firstName)) const update
            = Object.assign({'{'}
            {'}'}, ...badTripEntries.map(([tripId, trip]) ={'>'} ({'{'} [`${'{'}
            trip.__customerId{'}'}/${'{'}tripId{'}'}`] : null{'}'}))) console.log('update', update) //return
            firebase.database().ref('trips').update(update).then(() ={'>'} {'{'}
            // console.log('Done') //{'}'}){'}'}).catch(error ={'>'} {'{'}
            console.error('error', error){'}'})
          </pre>
        </li>

        <li>
          <p>Clean users table</p>
          <pre className="code">
            clear() firebase.database().ref('users').once('value').then(snapshot ={'>'} {'{'}
            const userEntries = Object.entries(snapshot.val()) console.log(userEntries) const badUserEntries =
            userEntries.filter(([userId, user]) ={'>'} {'{'}
            return userId[0] === '-'{'}'}) console.log(badUserEntries) const update = Object.assign({'{'}
            {'}'}, ...badUserEntries.map(([userId]) ={'>'} ({'{'}
            [`/drivers/${'{'}userId{'}'}`] : null, [`/customers/${'{'}userId
            {'}'}`] : null, [`/users/${'{'}userId{'}'}`] : null{'}'}))) console.log(update)
            console.log(Object.values(update).length) return firebase.database().ref().update(update).then(() ={'>'}{' '}
            {'{'}
            console.log('Done'){'}'}){'}'}).catch(error ={'>'} {'{'}
            console.error('error', error){'}'})
          </pre>
        </li>
      </ol>
    </div>
  );
}
